@import url("variables.css");

.heading {
	text-align: center;
	/* font-size: var(--fs-500);
	font-weight: var(--fw-400);
	font-family: var(--ff-body); */
}

.selectorField {
	display: flex;
	/* justify-content: center; */
	flex-wrap: wrap;
	gap: 0.6rem;
}

.option {
	color: var(--clr-inactive);
	fill: var(--clr-inactive);
	outline: 1px solid var(--clr-inactive);
	padding: 8px 16px;
	border-radius: 4px;
	list-style-type: none;
	cursor: pointer;
}

.selected {
	color: var(--clr-neutral-100);
	fill: var(--clr-neutral-100);
	background-color: var(--clr-primary-300);

	border: none;
	outline: 2px solid var(--clr-primary-300);
	/* outline: none; */
	font-weight: var(--fw-800);
}

.btnLg {
	display: block;
	/* width: 100%; */
	border: none;
	outline: none;
	text-align: center;
	cursor: pointer;
}

.btnPrimary {
	color: var(--clr-neutral-100);
	fill: var(--clr-neutral-100);
	background-color: var(--clr-primary-300);

	font-weight: var(--fw-400);
	cursor: pointer;
}

.btnSecondary {
	color: var(--clr-primary-300);

	fill: var(--clr-primary-300);
	background-color: var(--clr-neutral-100);
	border: 1px solid var(--clr-primary-300);

	/* padding: 0.5em; */
	/* width: 50%; */
	cursor: pointer;
}

.centralizedBtnDiv {
	display: flex;
	justify-content: center;
}
