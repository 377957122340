@import url(variables.css);

/****** UTILITY CLASSES ******/

.uppercase {
	text-transform: uppercase;
}

.italicize {
	font-style: italic;
}

.category-flex-container {
	display: flex;
	gap: 2.5px;
	color: var(--clr-accent);
	fill: var(--clr-accent);
}

.icon-flex-container {
	display: flex;
	align-items: center;
	gap: 3px;
}

.single-line {
	max-width: 40vw;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.first-child {
	padding-top: 0;
}

.hide {
	display: none;
}

.ff-title {
	font-family: var(--ff-title);
	line-height: 1.25;
}

.ff-body {
	font-family: var(--ff-body);
}

.ff-accent {
	font-family: var(--ff-accent);
}

.fs-100 {
	font-size: var(--fs-100);
}

.fs-200 {
	font-size: var(--fs-200);
}

.fs-300 {
	font-size: var(--fs-300);
}

.fs-400 {
	font-size: var(--fs-400);
}

.fs-500 {
	font-size: var(--fs-500);
}

.fs-600 {
	font-size: var(--fs-600);
}

.fs-700 {
	font-size: var(--fs-700);
}

.fs-800 {
	font-size: var(--fs-800);
}

.fw-400 {
	font-weight: var(--fw-400);
}

.fw-500 {
	font-weight: var(--fw-500);
}

.fw-600 {
	font-weight: var(--fw-600);
}

.fw-700 {
	font-weight: var(--fw-700);
}

.fw-800 {
	font-weight: var(--fw-800);
}
