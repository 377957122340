/* Tailwind styles */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/******  FONTS  *******/

/* Public Sans Extra Bold Italic */
@font-face {
  font-family: "Public Sans";
  src: url(../../public/fonts/Public_Sans/PublicSans-Italic-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Public Sans Variable Fonts */
@font-face {
  font-family: "Public Sans";
  src: url(../../public/fonts/Public_Sans/PublicSans-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 100 700;
  font-style: normal;
}

/* Public Sans Italic Variable Fonts */
@font-face {
  font-family: "Public Sans";
  src: url(../../public/fonts/Public_Sans/PublicSans-Italic-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Playfair Variable Fonts */
@font-face {
  font-family: "Playfair";
  src: url(../../public/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 100 200 300 400 500 600 700 800 900;
  font-style: normal;
}

/* Playfair Italic Variable Fonts */
@font-face {
  font-family: "Playfair";
  src: url(../../public/fonts/Playfair_Display/PlayfairDisplay-Italic-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 100 200 300 400 500 600 700 800 900;
  font-style: italic;
}

/* Urbanist Variable Fonts */
@font-face {
  font-family: "Urbanist";
  src: url(../../public/fonts/Urbanist/Urbanist-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 100 200 300 400 500 600 700;
  font-style: normal;
}

/* Urbanist Italic Variable Fonts */
@font-face {
  font-family: "Urbanist";
  src: url(../../public/fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 100 200 300 400 500 600 700;
  font-style: italic;
}

/* Martin Mono Variable Fonts */
@font-face {
  font-family: "Martian Mono";
  src: url(../../public/fonts/Martian_Mono/MartianMono-VariableFont_wdth.ttf);
  font-weight: 200 300 400 500 600 700 800;
}

/* Lato Regular Font */
@font-face {
  font-family: "Lato";
  src: url(../../public/fonts/Lato/Lato-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Lato Light Font */
@font-face {
  font-family: "Lato";
  src: url(../../public/fonts/Lato/Lato-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

/********* PRESETS *********/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  /* line-height: 1.5; */
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:root {
  --clr-primary-900: #000;
  --clr-primary-400: #101418;
  --clr-primary-300: #1c2025;

  --clr-accent: rgba(0, 128, 128, 0.8);
  --clr-accent-bright: rgb(0, 128, 128);
  --clr-inactive: #beb6c3;

  --clr-neutral-200: #00000094;
  --clr-neutral-100: hsl(0, 0%, 98%);
  --clr-neutral-100: #fafafa;

  --ff-title: "Public Sans", sans-serif;
  --ff-body: "Public Sans", sans-serif;
  --ff-accent: "Urbanist", sans-serif;

  /* --ff-accent: "Martian Mono", monospace; */
  /* --ff-accent: Monospac821 BT; */
  /* --ff-accent: monospace; */

  /* --fs-100: 0.6875rem;
	--fs-200: 0.71875rem;
	--fs-300: 0.75rem;
	--fs-400: 0.875rem;
	--fs-500: 1.1rem;
	--fs-600: 1.25rem;
	--fs-700: 1.5rem;
	--fs-800: 2rem; */

  --fs-100: 0.625rem;
  --fs-200: 0.6875rem;
  --fs-300: 0.71875rem;
  --fs-400: 0.75rem;
  --fs-500: 1.1rem;
  --fs-600: 1.25rem;
  --fs-700: 1.45rem;
  --fs-800: 2rem;

  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  --size-100: 0.125rem;
  /* --size-200: 0.1875rem; */
  --size-200: 0.25rem;
  --size-300: 0.5rem;
  --size-400: 1.2rem;
  --size-500: 1.25rem;
  --size-600: 1.5rem;
  --size-700: 2rem;
}

/*********** GENERAL STYLING *************/

body {
  margin: 0;
  max-width: 100%;
  /* display: flex;
	flex-direction: column; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--clr-neutral-100);
  /* color:; */
}

a {
  text-decoration: none;
  color: inherit;
}

.logo-emoji {
  font-size: 1.1rem;
  margin-right: 1px;
  /* font-style: normal; */
}

.logo {
  /* position: sticky; */
  width: 100%;
  font-family: "Public Sans", sans-serif;
  /* border-bottom: 1px solid  #e3e3e3; */
  /* background-image: linear-gradient(white, var(--clr-neutral-100)); */
  background-color: var(--clr-neutral-100);
  font-size: 1.35rem;
  font-weight: var(--fw-800);
  font-style: italic;
  height: 60px;
  /* padding: 0.8rem 0; */
  text-align: center;
}

@keyframes shimmer {
  from {
    background-position: -200px 0;
  }
  to {
    background-position: 100px 0;
  }
}

.shimmer,
.shimmer-div > * {
  animation: shimmer 2.5s linear infinite;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #e0e0e0 25%,
    #f0f0f0 50%,
    #e0e0e0 75%,
    #f0f0f0 100%
  );
  background-size: 200px 100%;
}

.banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../public/wkndbanner.jpg");
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 3;
}

.banner-content {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner-text {
  font-family: "Playfair", serif;
  line-height: 1.2;
  font-size: 2rem;
  margin-bottom: 30px;
  color: white;
}

.banner-fancy-text {
  font-style: italic;
  font-size: 2.8rem;
  /* font-family: "Playfair"; */
}

.banner button {
}

.scroll-down1 {
  /* width: 38px;
	height: 38px; */
  border-radius: 100%;
  border: 2px solid #00b4ff;
  border: 2px solid white;
  overflow: hidden;
  cursor: pointer;
  /* display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center; */
  margin: auto;
}

.scroll-down1 svg {
  fill: #00b4ff;
  fill: white;
  /* fill: var(--clr-accent); */

  animation: scrollDown1 1.8s ease infinite;
}

@keyframes scrollDown1 {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.week-header {
  font-family: "Public Sans", sans-serif;
  /* font-size: var(--fs-500); */
  /* font-weight: 100; */
  color: #111827;
  opacity: 0.7;
  font-style: italic;
  margin-bottom: 20px;
}

.event {
  display: flex;
  flex-direction: column;
  color: var(--clr-primary-400);
  margin-bottom: var(--size-400);
}

.event__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: var(--size-200);
}

.icons {
  margin-top: auto;
  /* align-self: flex-end; */
}

.icons-announcement {
  margin-top: 0.75rem;
}

.event__category {
  text-transform: capitalize;
}

.event__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.event__venue {
  color: rgba(0, 0, 0, 0.58);
  fill: rgba(0, 0, 0, 0.58);
}

.event__organizer {
  color: rgba(28, 32, 37, 0.96);
  letter-spacing: 0.46px;
}

.college {
  color: rgba(28, 32, 37, 0.96);
  letter-spacing: 0.46px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.college-label {
  text-transform: none;
}

.event__img {
  flex: 0 0 auto;
  /* width: 8.875rem; */
  width: 7.05rem;
  height: 11.125rem;
  border-radius: 8px;
  overflow: hidden;
}

.event__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hr {
  border: none;
  border-top: 2px solid #e3e3e3;
}

.wrapper {
  display: flex;
  /* flex-direction: row-reverse; */
  flex-direction: row;
  gap: 10px;
  flex-basis: 40%;
  justify-content: space-between;
  padding-bottom: var(--size-500);
  /* padding-bottom: 0.75rem; */
}

.announcement__info {
  gap: var(--size-200);

  /* border-radius: 8px;
	border: 2px solid #e3e3e3;
	padding: 4px; */
  /* gap: 0.3rem; */
}

.more-info {
  display: block;
  color: inherit;
  font-family: var(--ff-accent);
  font-weight: var(--fw-500);
  font-size: var(--fs-100);
  text-transform: lowercase;
  font-style: italic;
  letter-spacing: 1px;
  text-decoration: underline dotted;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--clr-accent);

  /* margin-top: 3px; */
}

.fancy-link {
  font-family: var(--ff-accent);
  font-weight: var(--fw-500);
  /* font-size: var(--fs-100); */
  text-transform: lowercase;
  font-style: italic;
  letter-spacing: 1px;
  text-decoration: underline dotted;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--clr-accent);
}

.align-center {
  text-align: center;
}

.more-info:hover {
  text-decoration-color: rgb(0, 128, 128, 1);
}

.visually-hidden {
  display: none;
}

footer {
  color: var(--clr-neutral-100);
  background-color: var(--clr-primary-300);
  width: 100%;
  text-align: center;
  padding: 1.2rem;
}

/* .announcement {
	margin: 2px 0;
}

.announcement .hr {
	display: none;
} */

/****** UTILITY CLASSES ******/

.uppercase {
  text-transform: uppercase;
}

.italicize {
  font-style: italic;
}

.category-flex-container {
  /* display: flex; */
  gap: 2.5px;
  color: var(--clr-accent);
  fill: var(--clr-accent);
}

.icon-flex-container {
  display: flex;
  align-items: center;
  gap: 3px;
}

.single-line {
  /* max-width: 100vw;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; */
}

.first-child {
  padding-top: 0;
}

.hide {
  display: none;
}

.ff-title {
  font-family: var(--ff-title);
  line-height: 1.25;
}

.ff-body {
  font-family: var(--ff-body);
}

.ff-accent {
  font-family: var(--ff-accent);
}

.fs-100 {
  font-size: var(--fs-100);
}

.fs-200 {
  font-size: var(--fs-200);
}

.fs-300 {
  font-size: var(--fs-300);
}

.fs-400 {
  font-size: var(--fs-400);
}

.fs-500 {
  font-size: var(--fs-500);
}

.fs-600 {
  font-size: var(--fs-600);
}

.fs-700 {
  font-size: var(--fs-700);
}

.fs-800 {
  font-size: var(--fs-800);
}

.fw-400 {
  font-weight: var(--fw-400);
}

.fw-500 {
  font-weight: var(--fw-500);
}

.fw-600 {
  font-weight: var(--fw-600);
}

.fw-700 {
  font-weight: var(--fw-700);
}

.fw-800 {
  font-weight: var(--fw-800);
}
