/******  FONTS  *******/


/* Public Sans Extra Bold Italic */
@font-face {
	font-family: "Public Sans";
	src: url(../../public/fonts/Public_Sans/PublicSans-Italic-VariableFont_wght.ttf)
		format("truetype");
	font-weight: 800;
	font-style: italic;
}

/* Public Sans Variable Fonts */
@font-face {
	font-family: "Public Sans";
	src: url(../../public/fonts/Public_Sans/PublicSans-VariableFont_wght.ttf)
		format("truetype");
	font-weight: 100 700;
	font-style: normal;
}

/* Public Sans Italic Variable Fonts */
@font-face {
	font-family: "Public Sans";
	src: url(../../public/fonts/Public_Sans/PublicSans-Italic-VariableFont_wght.ttf)
		format("truetype");
	font-weight: 100;
	font-style: italic;
}

/* Urbanist Variable Fonts */
@font-face {
	font-family: "Urbanist";
	src: url(../../public/fonts/Urbanist/Urbanist-VariableFont_wght.ttf)
		format("truetype");
	font-weight: 100 200 300 400 500 600 700;
	font-style: normal;
}

/* Urbanist Italic Variable Fonts */
@font-face {
	font-family: "Urbanist";
	src: url(../../public/fonts/Urbanist/Urbanist-Italic-VariableFont_wght.ttf)
		format("truetype");
	font-weight: 100 200 300 400 500 600 700;
	font-style: italic;
}

/* Martin Mono Variable Fonts */
@font-face {
	font-family: "Martian Mono";
	src: url(../../public/fonts/Martian_Mono/MartianMono-VariableFont_wdth.ttf);
	font-weight: 200 300 400 500 600 700 800;
}

/********* PRESETS *********/

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
* {
	margin: 0;
	padding: 0;
	font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

html,
body {
	height: 100%;
}

/* Set core body defaults */
body {
	text-rendering: optimizeSpeed;
	/* line-height: 1.5; */
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
	max-width: 100%;
	display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* @import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap"); */

:root {
	--clr-primary-900: #000;
	--clr-primary-400: #101418;
	--clr-primary-300: #1c2025;

	--clr-accent: rgba(0, 128, 128, 0.8);
	--clr-accent-bright: rgb(0, 128, 128);
	--clr-inactive: #BEB6C3;


	--clr-neutral-200: #00000094;
	--clr-neutral-100: hsl(0, 0%, 98%);

	--ff-title: "Public Sans", sans-serif;
	--ff-body: "Public Sans", sans-serif;
	--ff-accent: "Urbanist", sans-serif;

	/* --ff-accent: "Martian Mono", monospace; */
	/* --ff-accent: Monospac821 BT; */
	/* --ff-accent: monospace; */

	/* --fs-100: 0.6875rem;
	--fs-200: 0.71875rem;
	--fs-300: 0.75rem;
	--fs-400: 0.875rem;
	--fs-500: 1.1rem;
	--fs-600: 1.25rem;
	--fs-700: 1.5rem;
	--fs-800: 2rem; */

	--fs-100: 0.625rem;
	--fs-200: 0.6875rem;
	--fs-300: 0.71875rem;
	--fs-400: 0.75rem;
	--fs-500: 1.1rem;
	--fs-600: 1.25rem;
	--fs-700: 1.45rem;
	--fs-800: 2rem;

	--fw-400: 400;
	--fw-500: 500;
	--fw-600: 600;
	--fw-700: 700;
	--fw-800: 800;

	--size-100: 0.125rem;
	/* --size-200: 0.1875rem; */
	--size-200: 0.25rem;
	--size-300: 0.5rem;
	--size-400: 1.2rem;
	--size-500: 1.25rem;
	--size-600: 1.5rem;
	--size-700: 2rem;
}


